import { param } from 'jquery'
import CLRequest from '../request'


export function serveMain(params) {
  return CLRequest.get({ 
    url: "/api/common/allList",
    params
  })
}

export function addWordOrderApi(data) {
  return CLRequest.post({ 
    url: "/api/itr/addWorkOrder",
    data
  })
}

export function wordOrderListApi(data) {
  return CLRequest.post({
    url: "/api/itr/processList",
    data
  })
}

export function wordOrderDetailApi(params) {
  return CLRequest.get({
    url: "/api/itr/detail/" + params.id,
  })
}

export function companyListApi(data) {
  return CLRequest.post({ 
    url: "/api/itr/companyList",
    data
  })
}

export function reqVersionOCR(data) {
  return CLRequest.post({
    baseURL: "/",
    url: "/ocr-api/jaka/api/v0.1/oms/action",
    data
  })
}

export function reqApplyVirtual(data) {
  return CLRequest.post({
    baseURL: "/",
    url: "/api/virtual/applyVirtualMachine",
    data,
    timeout: 60000
  })
}

export function reqHasVirtualPhone(params) {
  return CLRequest.get({
    baseURL: "/",
    url: "/api/virtual/phoneHasCreateEcs",
    // url: "https://ota.jaka.com" + "/virtual/phoneHasCreateEcs",
    // url: "http://172.30.0.85:31004" + "/virtual/phoneHasCreateEcs",
    params
  })
}

export function reqHasVirtualEmail(params) {
  return CLRequest.get({
    baseURL: "/",
    url: "/api/virtual/emailHasCreateEcs",
    params
  })
}

export function reqSystemRecovery(params) {
  return CLRequest.get({
    baseURL: "/",
    url: "/api/virtual/systemRecovery",
    params
  })
}

export function reqUserInfo(token) {
  return CLRequest.get({
    url: "/login/getUserInfo/" + token,
  })
}
