import { ElMessage, ElLoading } from 'element-plus'
import i18n from '@/locale/index'

export function gotoUrl(url) {
  url && window.routerPush(url)
}

export function arrayToMap(array = [], key, value) {
  const obj = {}
  for (let item of array) {
    obj[item[key]] = item[value]
  }
  return obj
}

// 复制到剪切板
export function copyToClipboard(url) {
  var tempInput = document.createElement("input");
  tempInput.value = url; // 设置要复制的文本内容为指定的URL地址
  document.body.appendChild(tempInput);
  tempInput.select(); // 选中输入框中的文本内容
  document.execCommand("copy"); // 执行复制命令
  document.body.removeChild(tempInput); // 移除临时创建的输入框元素
}

export function getUrlParam(path, name) {
  path = decodeURIComponent(path)
  const reg = new RegExp('(^|&|\\?)' + name + '=([^&]*)(&|$)')
  const res = path.match(reg)
  let value = ''
  if (res) {
    value = res[2]
  }
  return value
}

export function getUrlLang(path, name) {
  path = decodeURIComponent(path)
  const reg = /(\.com|localhost)\/([a-zA-Z]{2})\//
  const res = path.match(reg)
  let value = ''
  if (res) {
    value = res[2]
  }
  console.log('getUrlLang', path, value)
  return value
}

export function formatPath(path) {
  let lang = getUrlLang(location.href)
  const p = lang ? `/${lang}${path}` : path
  return p
}

export function debounce (func, wait = 500, immediate = true) {
  let timeout = null
  return function () {
    console.log('debounce 1', timeout)
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function downloadFile(url) {
  let isMiniProgram = window.navigator.userAgent.toLocaleLowerCase().indexOf('miniprogram') > -1;
  if(isMiniProgram) {
    copyToClipboard(url);
    ElMessage({
      dangerouslyUseHTMLString: true,
      message: i18n.global.t('tips.copyToClipboard'),
      offset: 200,
    })
  } else{
    let a = document.createElement("a");
    a.setAttribute("href", url)
    a.setAttribute("download", url)
    // a.setAttribute("target", "_blank")
    let clickEvent = document.createEvent("MouseEvents")
    clickEvent.initEvent("click", true, true)
    a.dispatchEvent(clickEvent)
  }
}
