<template>
  <div class="my_dialog" :class="isDialogShow ? 'act' : ''">
    <div class="cover"></div>
    <div class="inner">
      <div class="top">
        <div class="title f_30">{{ t('sys.attention') }}</div> <img @click="cancelHandle" src="@/assets/imgl/cha.svg" alt="">
      </div>
      <div class="container">
        <div class="message f_20">{{ message }}</div>
        <div class="btns">
          <div class="cancel btn f_16" @click="cancelHandle">{{ t('sys.cancel') }}</div>
          <div class="confirm btn f_16" @click="confirmHandle">{{ t('sys.confirm') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
  message: String,
});
const isDialogShow = ref(false)
const emit = defineEmits(["update:confirm", "update:cancel"]);
const openDialog = () => {
  isDialogShow.value = true
}
const cancelHandle = () => {
  isDialogShow.value = false
  emit("update:cancel", "取消");
}
const confirmHandle = () => {
  isDialogShow.value = false
  emit("update:confirm", "确定");
}
defineExpose({
  openDialog,
});

</script>
<style scoped>
.my_dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  z-index: 2100;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
}

.my_dialog.act {
  opacity: 1;
  pointer-events: initial;
}

.my_dialog .inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 2;
  border-radius: 0.04rem;
  overflow: hidden;
}

.my_dialog .inner .top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.21vw;
  position: relative;
  background-color: #f1f1f1;
}

.my_dialog .inner .top .title {
  /* font-size: 1.56vw; */
  font-weight: 600;
  color: #000;
  text-align: center;
}

.my_dialog .inner .top img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.93vw;
  width: 1.04vw;
  cursor: pointer;
}

.my_dialog .container {
  padding: 0.34rem 0.68rem 0.2rem;
}

.my_dialog .container .message {
  text-align: center;
}

.my_dialog .container .btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.4rem;
}

.my_dialog .container .btns .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.21vw;
  color: #333;
  font-weight: 400;
  /* font-size: 0.83vw; */
  width: 9.38vw;
  height: 2.6vw;
  background-color: #d8d8d8;
  margin-right: 0.5rem;
  cursor: pointer;
}

.my_dialog .container .btns .btn.confirm {
  color: #fff;
  background-image: linear-gradient(120deg,
      #ff2135,
      #d80c1e,
      #d80c1e,
      #d80c1e,
      #af0514);
  margin-right: 0;
}

@media screen and (max-width: 1024px), (pointer: coarse) {
  .my_dialog .inner {
    width: calc(100vw - 40px);
  }

  .my_dialog .inner .top {
    height: 68px;
  }

  .my_dialog .inner .top img {
    width: 12px;
    right: 18px;
  }

  .my_dialog .container .btns .btn {
    width: 120px;
    line-height: 30px;
    height: 30px;
  }

  .my_dialog .container {
    padding: 34px 0 20px;
  }

  .my_dialog .container .btns .btn {
    margin-right: 15px;
  }
}
</style>