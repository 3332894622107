<template>
  <Header />
  <div class="loading"
       v-if="isLoading"></div>
  <!-- <keep-alive> -->
  <router-view />
  <!-- </keep-alive> -->
  <Footer />
</template>
<script setup>
import { ref, onMounted, onBeforeMount, watch } from 'vue'
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'

onBeforeMount(() => {
})
import { useRouter } from 'vue-router'
const router = useRouter()
const isLoading = ref(true)
let timer1
onMounted(() => {
})

const routerChange = () => {
  isLoading.value = true
  timer1 = setTimeout(() => {
    isLoading.value = false
    clearTimeout(timer1)
  }, 250)
}

watch(
  () => router.currentRoute.value,
  (newValue) => {
    if (router.currentRoute.value.path != '/commonQuestion' && router.currentRoute.value.path != '/preview/commonQuestion') {
      routerChange()
    }
  },
  { immediate: true }
)
</script>
<style scoped>
.loading {
  background-color: #f9f9f9;
  position: fixed;
  z-index: 80;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* animation: toOpacity 0.3s forwards; */
}
@keyframes toOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
<style>
:root {
  --el-color-primary: #d80c1e!important;
}
.el-button--primary {
  --el-button-hover-bg-color: rgba(216,12,30, 0.8)!important;
  --el-button-hover-border-color: rgba(216,12,30, 0.8)!important;
  --el-button-active-bg-color: rgba(216,12,30, 0.8)!important;
  --el-button-active-border-color: rgba(216,12,30, 0.8)!important;
}
/* @media screen and (orientation:  landscape) and (pointer: coarse) {
  body {
    transform-origin:top left;
    transform: rotate(90deg) translateY(-100%);
    width: 100vh !important;
    height: 100vw !important;
  }
} */
</style>
